<template>
  <div :class="show ? '' : 'hidden'">
    <v-progress-linear :value="value" style="margin: 0 auto" height="5" />
  </div>
</template>

<script>
import { sleep } from "@/utils/helper";

export default {
  name: "ProgressLine",
  data: () => ({
    value: 15,
    show: false,
    interval: {}
  }),
  created: function () {
    this.$store.watch(
      (state) => state.showProgressLine,
      () => {
        const showProgressLine = this.$store.state.showProgressLine;
        if (showProgressLine === true) {
          this.startProgress();
        }
        if (showProgressLine === false) {
          this.finishProgress();
        }
      }
    );
  },
  methods: {
    startProgress() {
      this.value = 0;
      this.show = true;
      this.interval = setInterval(() => {
        const ceil = (100 - this.value) / 5;
        const random = Math.floor(Math.random() * ceil);
        this.value += random;
      }, 500);
    },
    async finishProgress() {
      clearInterval(this.interval);
      this.value = 100;
      await sleep(600);
      this.show = false;
    }
  }
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
}
</style>
