<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <confirm-dlg ref="confirm" />

    <universal-toolbar
      :showDrawerMenu="true"
      :showLogo="!drawer && !$store.state.backDestination.show"
      @sendConnection="this.sendConnection"
    />

    <v-app-bar app color="white" height="60px" flat style="display: none">
      <!-- <v-autocomplete
        dense
        rounded
        filled
        hide-details
        hide-no-data
        hide-selected
        prepend-inner-icon="mdi-magnify"
        v-model="searchField"
        class="ml-5"
        height="40px"
        v-if="$screen.width > 600"
        :items="sitePaths"
        item-text="name"
        item-value="path"
        @blur="searchField = ''"
        @click="$router.push(searchField)"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content v-text="data.item.name" @click="$router.push(data.item.path)"></v-list-item-content>
          </template>
        </template>
      </v-autocomplete> -->

      <v-btn
        depressed
        color="primary"
        height="100%"
        tile
        class="ma-0"
        @click.stop="drawer = !drawer"
        v-if="!drawer && !$store.state.backDestination.show"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <v-btn
        tile
        depressed
        text
        style="height: 100%; padding: 0 0 0 10px"
        :style="{ padding: $screen.width > 600 ? '0 32px 0 32px' : '' }"
        color="primary"
        @click="back"
        v-if="$store.state.backDestination.show"
        data-cy="platformBack"
      >
        <v-icon left> mdi-chevron-left </v-icon>
        <span class="font-weight-bold" style="font-size: 0.875rem; font-weight: 600">{{
          $store.state.backDestination.name
        }}</span>
      </v-btn>
      <div class="d-flex align-center ml-5" v-if="$screen.width <= 600 && !$store.state.backDestination.show">
        <v-img src="@/assets/logo_cropped.png" max-width="35px" />
        <h3 class="ma-0 pa-0">
          <span style="font-weight: bold">{{ $t("tectechainName") }}</span>
        </h3>
      </div>
      <v-spacer></v-spacer>
      <language-switch :responsive="false" :arrow="false" />

      <!-- <div class="pnAccount">
        <v-chip class="mx-5" color="primary" text-color="white">
          <v-avatar left>
            <v-icon>mdi-circle-multiple-outline</v-icon>
          </v-avatar>
          <span class="hidden-xs-only">PN Account Balance:</span>
          {{ balance }}
        </v-chip>
      </div> -->

      <div>
        <v-tooltip bottom class="hidden-xs-only">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              @click="refreshPage"
              v-on="on"
              v-bind="attrs"
              width="40px"
              height="40px"
              style="margin-right: 6px"
              class="hidden-xs-only"
            >
              <img src="../assets/zeplin/refresh-1.svg" alt="" />
            </v-btn>
          </template>
          <span>{{ $t("navTopReload") }}</span>
        </v-tooltip>
        <v-tooltip bottom class="hidden-xs-only">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              v-bind="attrs"
              width="40px"
              height="40px"
              to="/platform/notifications"
              class="hidden-xs-only"
            >
              <v-badge overlap color="red" :content="notificationCount" :value="notificationCount">
                <img src="../assets/icon_pack/24px/icon/menu/notifications.svg" alt="refresh" />
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("navTopNotification") }}</span>
        </v-tooltip>
        <v-btn text color="primary" @click="logout" class="hidden-xs-only mr-3">
          <span class="font-weight-bold">{{ $t("navTopLogout") }}</span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main id="platform" style="padding-top: 60px" @readNotification="acknowledgeRead">
      <ProgressLine />
      <slot />
    </v-main>
  </div>
</template>

<script>
import config from "@/config";
import ProgressLine from "@/components/Common/ProgressLine";
import paths from "@/router/paths";
import MessageBox from "@/components/Common/MessageBox";
import { isEmpty } from "lodash/core";

import LanguageSwitch from "../components/Common/LanguageSwitch.vue";
import ConfirmDlg from "../components/Platform/ConfirmDlg.vue";
import { updateRoleInfo } from "../services/authService";
import UniversalToolbar from "../components/Platform/UniversalToolbar.vue";

export default {
  name: "Platform",
  props: {
    source: String
  },
  components: {
    ProgressLine,
    MessageBox,
    LanguageSwitch,
    ConfirmDlg,
    UniversalToolbar
  },
  data: () => ({
    adminSettings: [
      {
        src: "/platform/admin/users",
        icon: "mdi-account-circle",
        label: "Users"
      },
      {
        src: "/platform/admin/works",
        icon: "mdi-fountain-pen",
        label: "Works"
      },
      {
        src: "/platform/admin/events",
        icon: "mdi-calendar-check",
        label: "Events"
      },
      {
        src: "/platform/admin/referral",
        icon: "mdi-qrcode",
        label: "Referral"
      },
      {
        src: "/platform/admin/landing-info",
        icon: "mdi-newspaper",
        label: "Landing Info"
      },
      {
        src: "/platform/admin/orders",
        icon: "mdi-calculator-variant-outline",
        label: "Orders"
      }
    ],
    drawer: null,
    icons: [
      {
        name: "facebook",
        src: require("../assets/icon_pack/social icon/FB.png")
      },
      {
        name: "twitter",
        src: require("../assets/icon_pack/social icon/TT.png")
      },
      {
        name: "db",
        src: require("../assets/icon_pack/social icon/DB.png")
      }
    ],
    balance: 918,
    searchField: null,
    model: 1,
    roleList: [],
    accs: [],
    role: "",
    addr: "",
    profileImage: null,
    profileLoading: false,
    iconPhotoFileDescriptor: null,
    AN: false,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 0
    },
    showMenu: false,
    connection: null,
    expandProfileMenu: false
  }),
  metaInfo() {
    return {
      title: "Tectechain"
    };
  },

  async mounted() {
    if (this.$session.get("token")) {
      this.getUserProfile().then(this.marketingConsentBox);
      this.initConnection();
    }
  },
  beforeDestroy() {
    if (this.$session.get("token")) {
      this.connection.onclose = () => {};
      const payload = JSON.stringify({ action: 2 });
      this.connection.send(payload);
      this.$store.dispatch("clearImageURL");
      this.unsubscribe();
    }
  },
  methods: {
    logout: function () {
      this.$router.push("/", () => {
        this.$store.dispatch("clearImageURL");
        this.$session.destroy();
        this.$store.commit("addr", null);
        this.$store.commit("changeState", false);
        this.$store.commit("refreshUserProfile", {});
        localStorage.removeItem("workLastUpdate");
        localStorage.removeItem("redirect");
        this.$store.dispatch("resetState");
      });
    },
    async getUserProfile() {
      this.profileLoading = true;
      if (isEmpty(this.$store.state.userProfile)) {
        await this.$store.dispatch("loadUserProfile", {
          axios: this.$axios,
          config: config,
          token: this.$session.get("token")
        });
      }
      if (this.$store.state.userProfile.roleType === 0) {
        this.$session.set("role", "AN");
        this.AN = true;
      }
      if (Object.hasOwnProperty.call(this.$store.state.userProfile, "language")) {
        let langList = ["en", "zhHant", "zhHans"];
        this.$i18n.locale = langList[this.$store.state.userProfile.language];
      }
      this.profileLoading = false;
    },
    refreshAN() {
      const role = this.$session.get("role");
      if (role === "AN") {
        this.AN = true;
      } else {
        this.AN = false;
      }
    },
    refreshPage() {
      this.$router.go(0);
    },
    initConnection() {
      this.connection = new WebSocket(`${config.ws_api}/message/notification`);

      this.connection.onopen = () => {
        const token = this.$session.get("token");
        const payload = { action: 0, token: token };
        this.sendConnection(JSON.stringify(payload));
      };

      this.connection.onmessage = (event) => {
        const token = this.$session.get("token");
        if (!event.data) return;

        const msg = JSON.parse(event.data);

        switch (msg.type) {
          case 0:
            this.$store.dispatch("pushNotification", msg.data);
            break;
          case 1:
            this.$store.dispatch("pushNotification", this.$store.state.notifications.concat(msg.data));
            break;
          case 2:
            this.sendConnection(JSON.stringify({ action: 0, token: token }));
            break;
        }
      };

      this.connection.onclose = () => {
        this.initConnection();
      };

      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "readNotification") {
          this.sendConnection(this.$store.state.readNotification);
        }
      });
    },
    sendConnection(payload) {
      this.connection.send(payload);
    },
    acknowledgeRead(payload) {
      this.connection.send(payload);
    },
    clearChatMessages() {
      this.expandProfileMenu = false;
      this.$store.commit("profilePageChange", null);
      const token = this.$session.get("token");
      this.newChatMessages.forEach((elem) => {
        const payload = JSON.stringify({ action: 1, token: token, id: elem.id });
        this.connection.send(payload);
      });
    },
    changeTab(key) {
      this.$store.commit("profilePageChange", key);
    },
    back() {
      this.$router.push(this.$store.state.backDestination.path);
    },
    async marketingConsentBox() {
      let roleInfo = this.$store.state.userProfile.roleInfo;
      if (!roleInfo) {
        return;
      }
      if (Object.hasOwnProperty.call(roleInfo, "marketingConsent")) {
        return;
      }
      if (
        await this.$refs.confirm.open(
          this.$t("dialogMsg.marketing.messageTitle"),
          this.$t("dialogMsg.marketing.messageSubtitle"),
          { confirmBtn: this.$t("dialogMsg.marketing.agree"), backBtn: this.$t("dialogMsg.marketing.reject") }
        )
      ) {
        roleInfo.marketingConsent = true;
        updateRoleInfo(this.$axios, config, roleInfo, this.$session.get("token"));
      } else {
        roleInfo.marketingConsent = false;
        updateRoleInfo(this.$axios, config, roleInfo, this.$session.get("token"));
      }
    }
  },
  computed: {
    isLogin() {
      return !!this.$session.get("token");
    },
    userProfile() {
      return this.$store.state.userProfile;
    },

    sidebar() {
      return [
        {
          src: "/platform/bazaar",
          icon: require("@/assets/icon_pack/24px/icon/menu/dashboards_active.svg"),
          label: this.$t("navBazaar"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        },
        {
          src: "/platform/mycollection",
          icon: require("../assets/icon_pack/24px/icon/menu/mycollection.svg"),
          label: this.$t("navCollections"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        },
        {
          src: "/platform/chat",
          icon: require("../assets/icon_pack/24px/icon/menu/email.svg"),
          label: this.$t("navChatroom"),
          click: this.clearChatMessages
        },
        {
          src: "/platform/notifications",
          icon: require("../assets/icon_pack/24px/icon/menu/chat.svg"),
          label: this.$t("navNotification"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        }
      ];
    },
    userSettings() {
      return [
        {
          src: "/platform/profile",
          icon: require("../assets/icon_pack/24px/icon/menu/User Profile.svg"),
          label: this.$t("navProfile"),
          subgroup: [
            {
              src: "/platform/profile",
              icon: require("../assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Basic"),
              key: "basic"
            },
            {
              src: "/platform/profile",
              icon: require("../assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Personal"),
              key: "personal"
            },
            {
              src: "/platform/profile",
              icon: require("../assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Social"),
              key: "social"
            }
          ]
        }
      ];
    },
    showProgressLine() {
      return this.$store.state.showProgressLine;
    },
    notificationCount() {
      return this.$store.state.notifications.filter((e) => {
        return e.notificationType !== 20 && e.isNew;
      }).length;
    },
    newChatMessages() {
      let notifications = this.$store.state.notifications.filter((e) => e.notificationType === 20);
      return notifications;
    },
    profileTab: {
      get: function () {
        return this.$store.state.profilePage;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("profilePageChange", newValue);
      }
    },
    sitePaths() {
      return paths.filter((elem) => {
        return elem.meta.searchable && elem.meta.requiresAuth;
      });
    }
  },
  watch: {
    userProfile() {
      this.refreshAN();
    }
  }
};
</script>

<style scoped>
.noBorder {
  border-left: 5px solid #ffffff;
  font-weight: normal;
  padding-left: 26px;
}

.noBorder:hover {
  border-left: 5px solid #e7eaee;
  background-color: #e7eaee;
  font-weight: normal;
}

.border {
  border-left: 5px solid #8bb35d;
}

.border .v-list-item__content {
  font-weight: 600;
}

.border:hover {
  border-left: 5px solid #8bb35d;
}

.noBorderProfile {
  border-left: 5px solid #ffffff;
  padding-left: 10px;
  font-weight: normal;
}

.borderProfile {
  border-left: 5px solid #8bb35d;
}

.subgroup {
  left: 32px;
  border-left: 1px solid #f5f5f5;
}
.subgroup:hover {
  background-color: #e7eaee;
}

.selectedSubgroup .v-list-item__subtitle {
  color: #63a3c3;
  font-weight: 600;
}
</style>
